import React from "react"
import GarantiPay from "../../../assets/images/garanti-pay-kucuk.png"
import androidApp from "../../../assets/images/android-app.png"
import iosApp from "../../../assets/images/ios-app.png"
import {FormattedMessage} from "react-intl";

class GarantiPayTab extends React.Component{
    render() {
        return <div className={"text-center padding-top-25 padding-bottom-25"}>
            <div className="ml-auto mr-auto" style={{width: "75%"}}>
                <img src={GarantiPay} alt=""/>
                <FormattedMessage id={"client.garanti_pay_tab.info"}/>

                <FormattedMessage id={"client.garanti_pay_tab.mobile_app"}/>
                <p>
                    <ul className={"list-unstyled align-items-center"}>
                        <li className={"d-inline mr-2"}><a target={"_blank"} href={"https://play.google.com/store/apps/details?id=com.garanti.bonusapp&hl=tr&gl=US"}><img src={androidApp} height={32} alt=""/></a></li>
                        <li className={"d-inline"}><a target={"_blank"} href={"https://apps.apple.com/us/app/bonusfla%C5%9F/id994503642"}><img src={iosApp} height={32} alt=""/></a></li>
                    </ul>
                </p>
            </div>
        </div>;
    }
}

export default GarantiPayTab