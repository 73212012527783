import React from "react";
import classnames from "classnames";
import {Link} from "react-router-dom";
import preloader from "../../../assets/images/preloader.gif";
import AdminSidebarProfile from "./AdminSidebarProfile";
import {get_app_lang, get_main_config, set_app_lang} from "../../../index";
import {Scrollbars} from 'react-custom-scrollbars';
import MobileHeaderAdmin from "../../../MobileHeaderAdmin";
import AdminMenuComp from "./AdminMenuComp";
import {languages, mobileWidthEnd} from "../../../Config";
import {FormattedMessage} from "react-intl"
import Button from "@material-ui/core/Button";
import {Menu, MenuItem} from "@material-ui/core";
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {HiTranslate} from "react-icons/all";
import LangSwitcher from "../../../Components/LangSwitcher";

class AdminSidebar extends React.Component {
    render() {
        let {changeMenu, openedMenu, selectedMenu, changeSelectedMenu} = this.props
        let {preloaderShow} = this.props
        return (
            <div className="dashboard-sidebar" style={{width: 280}}>
                <div className="dashboard-sidebar-inner" data-simplebar>
                    <div className="dashboard-nav-container w-100 float-left">

                        <div className={"d-none d-md-block"}>
                            <div className="w-100 text-center d-block float-left">
                                <div id="logo" className={"bg-light w-100 pt-2 pb-2 mb-3 border-bottom"}>
                                    <Link to={"/admin"} onClick={() => {
                                        changeMenu("home")
                                        changeSelectedMenu({})
                                    }}><img src={get_main_config("system_general", "logo_url")} alt={""}/></Link>
                                </div>
                                <div className={classnames("ajax-processing", {
                                    "d-none": !preloaderShow
                                })}>
                                    <img src={preloader}
                                         style={{
                                             width: "30px",
                                             float: "left",
                                             marginLeft: 30
                                         }}
                                    />
                                    <span><FormattedMessage id={"admin.admin_sidebar.please_wait"}/></span>
                                </div>
                            </div>
                            <div>
                                <AdminSidebarProfile
                                    history={this.props.history}
                                />
                            </div>
                        </div>
                        {window.innerWidth < mobileWidthEnd && <div className={"d-flex d-md-none"}>
                            <MobileHeaderAdmin
                                toggle={this.props.toggleMenu}
                                toggleProfile={this.props.toggleProfile}
                                changeMenu={changeMenu}
                                changeSelectedMenu={changeSelectedMenu}/>
                        </div>}
                        <div className="dashboard-nav">
                            <div className="dashboard-nav-inner">
                                <Scrollbars style={{width: "100%", height: "calc(100vh - 118px)"}}>
                                    <AdminMenuComp {...this.props} />

                                    <div className="text-center margin-top-10">
                                        <Link className={"btn btn-paymendo-grad"} to={'/cp'}><i
                                            className={"icon-line-awesome-exchange text-white"}/> <FormattedMessage
                                            id={"admin.admin_sidebar.go_client_panel"}/></Link>
                                    </div>
                                    <LangSwitcher
                                        boxStyle={{width: 278, bottom: 0, padding: "7.5px 0", position: "fixed"}}/>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminSidebar