import React from "react"
import * as classnames from "classnames";

class ShowPrice extends React.Component{

    formatMoney(number, decPlaces, decSep, thouSep) {
        let sign,i,j;
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
        decSep = typeof decSep === "undefined" ? "," : decSep
        thouSep = typeof thouSep === "undefined" ? "." : thouSep
        sign = number < 0 ? "-" : ""
        i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    }


    render() {
        let {amount, currencyprefix, currencysuffix} = this.props

        return <span className={classnames("price-view", {
            "text-danger": amount<0
        })}{...this.props}>{currencyprefix||''}{this.formatMoney(amount)}{currencysuffix||''}</span>;
    }
}
export default ShowPrice