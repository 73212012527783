var installment_settings = {
    "2installments": {
        title: "2 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "3installments": {
        title: "3 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "4installments": {
        title: "4 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "5installments": {
        title: "5 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "6installments": {
        title: "6 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "7installments": {
        title: "7 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "8installments": {
        title: "8 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "9installments": {
        title: "9 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "10installments": {
        title: "10 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "11installments": {
        title: "11 Taksit",
        description: "",
        type: "text",
        value: ""
    },
    "12installments": {
        title: "12 Taksit",
        description: "",
        type: "text",
        value: ""
    }
};
var payten_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "store_key": {
        title: "3D Güvenlik Şifresi - Storekey",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "password",
        value: "",
    },
    "api_user_name": {
        title: "API Kullanıcısı",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "text",
        value: "",
    },
    "api_user_password": {
        title: "API Kullanıcı Şifresi",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "password",
        value: "",
    },
    "method": {
        title: "Ödeme Metodu",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "select",
        value: "",
        options: [
            {
                value: "non_secure",
                label: "Non Secure",
            },
            {
                value: "3D",
                label: "3D Secure"
            },
            {
                value: "3D_PAY",
                label: "3D Pay"
            },
            {
                value: "3D_PAY_HOSTING",
                label: "3D Pay Hosting"
            }
        ]
    }
    // , "is_oos": {
    //     title: "Ortak ödeme sayfası kullan?",
    //     description: "Banka ortak ödeme sayfasını kullanmak için seçebilirsiniz",
    //     type: "checkbox",
    //     default: "1",
    // }
};
var ykb_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza ID(MID)",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "terminal_id": {
        title: "Terminal ID(TID)",
        description: "Banka tarafından atanan terminal numarasını giriniz.",
        type: "text",
        value: "",
    },
    "posnet_id": {
        title: "Posnet ID(PID)",
        description: "Banka tarafından atanan posnetnumarasını giriniz.",
        type: "text",
        value: "",
    },
    "encryption_key": {
        title: "Şifreleme Anahtarı",
        description: "Bu bilgiyi sanal POS yönetim ekranından sıfırlayabilirsiniz.",
        type: "text",
        value: "",
    },
    "mac_algorithm": {
        title: "MAC Doğrulama Yöntemi",
        type: "select",
        value: "standard",
        options: [
            {
                value: "standard",
                label: "Standard",
            },
            {
                value: "alternative",
                label: "Alternative"
            }
        ]
    },
    "method": {
        title: "Ödeme Metodu",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "select",
        value: "",
        options: [
            {
                value: "non_secure",
                label: "Non Secure",
            },
            {
                value: "3D",
                label: "3D Secure"
            }
        ]
    }
    // ,    "is_oos": {
    //     title: "Ortak ödeme sayfası kullan?",
    //     description: "Banka ortak ödeme sayfasını kullanmak için seçebilirsiniz",
    //     type: "checkbox",
    //     default: "1",
    // }
};

var alpos_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza Numarası",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "terminal_id": {
        title: "Terminal ID",
        description: "Banka tarafından atanan terminal numarasını giriniz.",
        type: "text",
        value: "",
    },
    "posnet_id": {
        title: "Posnet ID(PID)",
        description: "Banka tarafından atanan posnet numarasını giriniz.",
        type: "text",
        value: "",
    },
    "epos_no": {
        title: "Epos No",
        description: "Banka tarafından atanan EPOS no giriniz.",
        type: "text",
        value: "",
    },
    "encryption_key": {
        title: "Şifreleme Anahtarı",
        description: "Bu bilgiyi sanal POS yönetim ekranından sıfırlayabilirsiniz.",
        type: "text",
        value: "",
    }
};
var payflex_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "store_key": {
        title: "3D Güvenlik Şifresi - Storekey",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "password",
        value: "",
    },
    "method": {
        title: "Ödeme Metodu",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "select",
        value: "",
        options: [
            {
                value: "non_secure",
                label: "Non Secure",
            },
            {
                value: "3D",
                label: "3D Secure"
            }
        ]
    }
};
var bkmexpress_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza no",
        description: "BKMExpress tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "private_key": {
        title: "Mağaza Gizli Anahtar(private key)",
        description: "BKM Express'e iletmiş olduğunuz private key bilgisini yazınız.",
        type: "password",
        value: "",
    },
    "environment": {
        title: "Ortam",
        description: "İşlemlerin gerçekleşeceği ortamı seçiniz.",
        type: "select",
        value: "",
        options: [
            {
                value: 'DEV',
                label: 'Geliştirme ortamı (DEV)'
            },
            {
                value: 'LOCAL',
                label: 'Yerel ortam (LOCAL)'
            },
            {
                value: 'SANDBOX',
                label: 'Test ortamı (SANDBOX)'
            },
            {
                value: 'PREPROD',
                label: 'Gerçek ortam öncesi (PREPROD)'
            },
            {
                value: 'PRODUCTION',
                label: 'Gerçek ortam'
            }
        ]
    }
};
var payflex_settings_vakif = Object.assign(payflex_settings, {
    "terminal_id": {
        title: "Terminal No",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "text",
        value: "",
    }
});
var intertech_settings = Object.assign(payten_settings, {});
var garanti_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "terminal_id": {
        title: "Terminal no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "terminal_user_id": {
        title: "Terminal Kullanıcısı",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "provaut": {
        title: "Terminal Kullanıcı Şifresi",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "provrfn": {
        title: "İade Kullanıcısı Şifresi",
        description: "Banka yönetim panelinden ayarladığınız PROVRFN kullanıcı şifresini giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "store_key": {
        title: "3D Güvenlik Şifresi - Storekey",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "password",
        value: "",
    },
    "method": {
        title: "Ödeme Metodu",
        description: "Bu bilgiye sanal POS yönetim ekranından ulaşabilirsiniz.",
        type: "select",
        value: "",
        options: [
            {"value": "3D", "label": "3D Secure"},
            {"value": "3D_PAY", "label": "3D Pay"},
            {"value": "3D_FULL", "label": "3D Full"},
            {"value": "3D_HALF", "label": "3D Half"},
            {"value": "3D_OOS_PAY", "label": "3D OOS Pay"},
            {"value": "3D_OOS_FULL", "label": "3D OOS Full"},
            {"value": "3D_OOS_HALF", "label": "3D OOS Half"},
            {"value": "OOS_PAY", "label": "OOS_PAY"},
            {"value": "non_secure", "label": "Non Secure"}
        ]
    }
    // , "is_oos": {
    //     title: "Ortak ödeme sayfası kullan?",
    //     description: "Banka ortak ödeme sayfasını kullanmak için seçebilirsiniz",
    //     type: "checkbox",
    //     default: "1",
    // }
};
var garantipay_settings = Object.assign({}, garanti_settings);
garantipay_settings.store_name = {
    title: "Mağaza Adı",
    description: "GarantiPay arayüzünde geçecek mağaza adınızı giriniz.",
    type: "text",
    value: ""
};
garantipay_settings.max_installment = {
    title: "Azami Taksit Sınırı",
    description: "GarantiPay üzerinde izin verilecek azami taksit adedini seçiniz.",
    type: "select",
    value: "",
    options: [
        {"value": "1", "label": "Sadece Peşin"},
        {"value": "2", "label": "2"},
        {"value": "3", "label": "3"},
        {"value": "4", "label": "4"},
        {"value": "5", "label": "5"},
        {"value": "6", "label": "6"},
        {"value": "7", "label": "7"},
        {"value": "8", "label": "8"},
        {"value": "9", "label": "9"},
        {"value": "10", "label": "10"},
        {"value": "11", "label": "11"},
        {"value": "12", "label": "12"}
    ]
};
delete garantipay_settings.method;
garantipay_settings.method = {
    type: "hidden",
    value: "standard"
}

// delete garantipay_settings.is_oos;

var kuveyt_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "customer_id": {
        title: "Müşteri no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "api_user_name": {
        title: "API Kullanıcı Adı",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "api_user_password": {
        title: "API Kullanıcı Şifresi",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "password",
        value: "",
        default: "",
        class: ""
    }
};
var paytr_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "merchant_id": {
        title: "Mağaza no",
        description: "Banka tarafından atanan mağaza numarasını giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "merchant_key": {
        title: "Üye işyeri şifresi(Merchant_key)",
        description: "Bu bilgiyi PayTR panelinizden edinebilirsiniz.",
        type: "password",
        value: "",
        default: "",
        class: ""
    },
    "merchant_salt": {
        title: "Üye işyeri gizli anahtarı(Merchant_salt)",
        description: "Bu bilgiyi PayTR panelinizden edinebilirsiniz.",
        type: "password",
        value: "",
        default: "",
        class: ""
    }
};
var parampos_settings = {
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    },
    "client_code": {
        title: "Müşteri Kodu(Client Code)",
        description: "Param tarından size iletilen müşteri kodunu giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "client_username": {
        title: "API Kullanıcı Adı(Client Username)",
        description: "Param panelinizden aldığınız API kullanıcı adını giriniz.",
        type: "password",
        value: "",
        default: "",
        class: ""
    },
    "client_password": {
        title: "API Kullanıcı Şifresi(Client Password)",
        description: "Param panelinizden aldığınız API kullanıcı şifresini giriniz.",
        type: "password",
        value: "",
        default: "",
        class: ""
    },
    "client_guid": {
        title: "Müşteri GUID",
        description: "Param panelinizden aldığınız GUID bilgisini giriniz.",
        type: "text",
        value: "",
        default: "",
        class: ""
    },
    "add_advance_commission": {
        title: "Peşin Ödemelerde Komisyon Uygula",
        description: "Peşin ödeme işlemlerinde de komisyon uygulanmasını istiyorsanız işaretleyiniz.",
        type: "checkbox",
        value: "",
        default: "",
        class: ""
    }
};
var payfor_settings = Object.assign(payten_settings, {});

export const Banks = {
    "Akbank": {
        image: require('./assets/images/banks/akbank.png'),
        name: "Akbank T.A.Ş - Payten",
        class: "Akbank",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "AkbankInnova": {
        image: require('./assets/images/banks/akbank.png'),
        name: "Akbank T.A.Ş - Payflex",
        class: "AkbankInnova",
        settings: Object.assign(payflex_settings),
        installments: installment_settings
    },
    "Albaraka": {
        image: require('./assets/images/banks/albaraka.png'),
        name: "Albaraka Türk Katılım Bankası",
        class: "Albaraka",
        settings: Object.assign(alpos_settings),
        installments: installment_settings
    },
    "BkmExpress": {
        image: require('./assets/images/banks/bkmexpress-logo.png'),
        name: "BKMExpress",
        class: "BkmExpress",
        settings: Object.assign(bkmexpress_settings),
        installments: null
    },
    "CardPlus": {
        image: require('./assets/images/banks/cardplus.png'),
        name: "Cardplus",
        class: "Cardplus",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "Denizbank": {
        image: require('./assets/images/banks/denizbank.png'),
        name: "Denizbank - InterTech",
        class: "Denizbank",
        settings: Object.assign(intertech_settings),
        installments: installment_settings
    },
    "Garanti": {
        image: require('./assets/images/banks/garanti.png'),
        name: "Garanti BBVA",
        class: "Garanti",
        settings: Object.assign(garanti_settings),
        installments: installment_settings
    },
    "GarantiPay": {
        image: require('./assets/images/banks/garanti-pay.png'),
        name: "Garanti BBVA - GarantiPay",
        class: "GarantiPay",
        settings: Object.assign(garantipay_settings),
        installments: []
    },
    "Halkbank": {
        image: require('./assets/images/banks/halkbank.png'),
        name: "Halkbank - Payten",
        class: "Halkbank",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "Isbank": {
        image: require('./assets/images/banks/isbank.png'),
        name: "Türkiye İş Bankası - Payten",
        class: "Isbank",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "IsbankInnova": {
        image: require('./assets/images/banks/isbank.png'),
        name: "Türkiye İş Bankası - Payflex",
        class: "IsbankInnova",
        settings: Object.assign(payflex_settings),
        installments: installment_settings
    },
    "Ingbank": {
        image: require('./assets/images/banks/ingbank.png'),
        name: "Ingbank - Payten",
        class: "Ingbank",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "Kuveytturk": {
        image: require('./assets/images/banks/kuveytturk.png'),
        name: "Kuveyttürk - BOA",
        class: "Kuveytturk",
        settings: Object.assign(kuveyt_settings),
        installments: null
    },
    "Sekerbank": {
        image: require('./assets/images/banks/sekerbank.png'),
        name: "Şekerbank - Payten",
        class: "Sekerbank",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "Ptt": {
        image: require('./assets/images/banks/ptt.png'),
        name: "Ptt - InterTech",
        class: "Ptt",
        settings: Object.assign(intertech_settings),
        installments: installment_settings
    },
    "QnbFinansbank": {
        image: require('./assets/images/banks/finansbank.png'),
        name: "QNB Finansbank - Payten",
        class: "QnbFinansbank",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "QnbPayFor": {
        image: require('./assets/images/banks/finansbank.png'),
        name: "QNB Finansbank - Payfor",
        class: "QnbPayFor",
        settings: Object.assign(payfor_settings),
        installments: installment_settings
    },
    "Parampos": {
        image: require('./assets/images/banks/param.png'),
        name: "Param",
        class: "Parampos",
        settings: Object.assign(parampos_settings),
        installments: null
    },
    "PayTR": {
        image: require('./assets/images/banks/paytr.png'),
        name: "PayTR",
        class: "PayTR",
        settings: Object.assign(paytr_settings),
        installments: null
    },
    "Teb": {
        image: require('./assets/images/banks/teb.png'),
        name: "TEB - Payten",
        class: "Teb",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "Tfkb": {
        image: require('./assets/images/banks/turkiyefinans.png'),
        name: "Türkiye Finans - Payten",
        class: "Tfkb",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "VakifbankInnova": {
        image: require('./assets/images/banks/vakifbank.png'),
        name: "Vakıfbank - Payflex",
        class: "VakifbankInnova",
        settings: Object.assign(payflex_settings_vakif),
        installments: installment_settings
    },
    "Ykb": {
        image: require('./assets/images/banks/ykb.png'),
        name: "YapıKredi Bankası - Posnet",
        class: "Ykb",
        settings: Object.assign(ykb_settings),
        installments: installment_settings
    },
    "Ziraat": {
        image: require('./assets/images/banks/ziraatbank.png'),
        name: "Ziraat Bankası - Payten",
        class: "Ziraat",
        settings: Object.assign(payten_settings),
        installments: installment_settings
    },
    "ZiraatInnova": {
        image: require('./assets/images/banks/ziraatbank.png'),
        name: "Ziraat Bankası - Payflex",
        class: "ZiraatInnova",
        settings: Object.assign(payflex_settings),
        installments: installment_settings
    },
    "ZiraatKatilim": {
        image: require('./assets/images/banks/ziraatkatilim.png'),
        name: "Ziraat Katılım",
        class: "ZiraatKatilim",
        settings: Object.assign(payfor_settings),
        installments: installment_settings
    }
}

export function getBankObject(bankClass) {
    return Banks[bankClass]
}