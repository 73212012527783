import React from "react"
import {
    BottomNavigation,
    BottomNavigationAction,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    IconButton
} from "@material-ui/core";
import GlRequest from "../Lib/GlRequest";
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, ListItem, ListItemAvatar, ListItemIcon, ListItemText} from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Autorenew from '@material-ui/icons/Autorenew';
import moment from "moment-timezone";
import classnames from "classnames";
import {Link} from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Button, CardFooter, Col, Row} from "reactstrap";
import {FaExchangeAlt, IoReload} from "react-icons/all";
import {Skeleton} from "@material-ui/lab";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";


const LoadingLastLoginItem = (props) => {
    return <div className={"mt-2 mb-2 padding-left-10"}>
        <Row>
            <Col lg={3}>
                <Skeleton variant={"circle"} width={32} height={32}/>
            </Col>
            <Col lg={9}>
                <Skeleton variant={"text"} width={"60%"} height={24}/>
                <Skeleton variant={"text"} width={"35%"} height={15}/>
            </Col>
        </Row>
    </div>
}

const LastLoginSingle = (props1) => {
    let props = props1.attributes
    const isAdmin = typeof props.roles === "object" && (props.roles).indexOf("ROLE_ADMIN") > -1
    let time = (props.last_login)
    // time = time.replaceAll(' ', '') + '+00:00'


    return <ListItem
        onClick={() => {
            props1.history.push(`/admin/clients/${props.id}`)
        }}
        className={"last-login-row pt-0 pb-0 cursor-pointer border-radius"}>
        <ListItemAvatar style={{
            width: 32,
            height: 32
        }}>
            <Avatar style={{
                width: 24,
                height: 24
            }}>
                {isAdmin ? <SupervisorAccountIcon/> : <AccountCircle/>}
            </Avatar>
        </ListItemAvatar>
        <ListItemText title={moment.utc(time).tz("Europe/Istanbul").format("DD/MM/YYYY HH:mm:ss")}
                      primary={`#${props.id} ${props.firstname} ${props.lastname}`}
                      secondary={moment.utc(time).locale("tr").fromNow()}/>
    </ListItem>
}


class LastLoginWidget extends React.Component {
    state = {
        clients: [],
        reloading: false,
        activeType: "client"
    }

    load = () => {
        this.setState({reloading: true})
        let count = this.props.count

        if (!count)
            count = 5
        let role_params = '?role=' + ((this.state.activeType === "admin") ? "ROLE_ADMIN" : "CLIENT")
        GlRequest.get('/api/v2/user/last-login/' + count + role_params)
            .then(r => r.json())
            .then(r => {
                this.setState({clients: r.data, reloading: false})
            })
    }

    componentDidMount() {
        this.load()

    }

    handleChange = (event) => {
        let newValue = event.target.value
        this.setState({
            activeType: newValue
        }, () => this.load())
    };

    render() {
        let {clients, reloading} = this.state
        let {type} = this.props
        let headText
        if (this.state.activeType === "admin")
            headText =  react_intl.formatMessage({id:"components.last_login_widget_admins"})
        else
            headText =  react_intl.formatMessage({id:"components.last_login_widget_customers"})

        return <Card className={"margin-bottom-30"}>
            <CardHeader
                titleTypographyProps={{variant: 'h6'}}
                disableTypography
                title={<><Autorenew
                    className={classnames({
                        "cssspin": reloading,
                        "d-none": !reloading
                    })}/><FormattedMessage id={"components.last_login_widget_last_login"} /> <strong className={"text-underline"}>{headText}</strong> <FaExchangeAlt
                    className={"cursor-pointer"} onClick={() => {
                    this.handleChange({
                        target: {
                            value: this.state.activeType === "admin" ? "client" : "admin"
                        }
                    })
                }}/></>}
                className={"border-bottom paymendo-grad text-white pl-3 rounded-0 m-0"}
            />
            <CardContent className={"pt-0 pb-0"} style={{height: 250}}>
                {reloading && <>{[0, 1, 2, 3, 4].map((item,i) => <LoadingLastLoginItem key={`last-login-${i}`} />)}</>}
                {!reloading && clients.map((item,i) => <LastLoginSingle key={`LastLogin-${i}`} history={this.props.history} {...item} />)}
            </CardContent>
            <CardFooter className={"text-center bg-light"}>
                <span className={"cursor-pointer"} disabled={this.state.reloading} color={"link"} onClick={() => { this.load() }}><IoReload /> Yenile</span>
            </CardFooter>
        </Card>;
    }
}

export default LastLoginWidget