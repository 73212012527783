import React from 'react';
import AsyncSelect from 'react-select/async';
import {components} from "react-select"
import _ from 'lodash';
import request from "./../Lib/GlRequest"
import CustomCheckbox from "../Elements/CustomCheckbox";
import {FormattedMessage} from "react-intl";

export const react_select_zIndex2 = (provided, state) => ({
    ...provided,
    // zIndex: 2
})
export const react_select_zIndex99 = (provided, state) => ({
    ...provided,
    // zIndex: 99
})

export const GlOption = (props) => {
    return <components.Option {...props}>
        <div className={"d-flex"}>
            <CustomCheckbox
                style={{pointerEvents: "none"}}
                checked={props.isSelected}
                onChange={e => null}
            />{" "}
            <label className={"select-option-label"}>{props.label} </label>
        </div>
    </components.Option>


}

export const GlValueContainer = ({children, getValue, ...props}) => {

    const length = children[0].length
    let tmpChildren = [...children];

    if(props.selectProps.menuIsOpen)
        tmpChildren[0] = <span className="item-selected-text" />
    else if(length == 1){
        tmpChildren[0] = <span className="item-selected-text">{getValue()[0].label}</span>
    } else if(length >= 2){
        tmpChildren[0] = <span className="item-selected-text">{length} <FormattedMessage id={"components.GSelect_chosen"}/></span>
    }
    return (
        <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
    );

    /*
    let length = getValue().length;
    if(length < 2)
        return <components.ValueContainer {...props} />

    return (
        <components.ValueContainer {...props}>
            {length === 1 && !props.selectProps.menuIsOpen ?
                <span className="item-selected-text">{getValue()[0].label}</span> : ""}
            {length > 1 && !props.selectProps.menuIsOpen && <span
                className="item-selected-text">{length}{typeof props.countItemLabel === "string" && `${props.countItemLabel} `}
                {" "}<FormattedMessage id={"components.GSelect_chosen"} tagName={"span"}/>
            </span>}
            {React.cloneElement(children[1])}
        </components.ValueContainer>
    ); */
}

export const react_select_zIndex2_style = {
    container: react_select_zIndex2
}
export const react_select_zIndex99_style = {
    container: react_select_zIndex99
}

const customSelectStyles = react_select_zIndex2_style

class GlSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: [],
            defaultOptions: this.props.defaultOptions,
            placeholder: this.props.placeholder,
            inputId: this.props.inputId
        };
        this.loadDefaultOptions = this.loadDefaultOptions.bind(this)
    }

    componentDidMount() {
        this.getOptions = _.debounce(this.getOptions.bind(this), 500);
        this.loadDefaultOptions(this.props.firstSelected && typeof this.props.defaultValue !== "object");
    }

    handleChange = selectedOption => {
        if (selectedOption === null)
            selectedOption = []
        this.setState({
            selectedOption: selectedOption
        });
        if (this.props.onChange) {
            if (typeof selectedOption === "object")
                this.props.onChange(selectedOption);
            else
                this.props.onChange(selectedOption.value);
        }
    };

    mapOptionsToValues = options => {
        if (typeof options !== "undefined" && options.length && options[0].type === "User") {
            return options.map(option => ({
                value: option.id,
                label: `#${option.id} - ${option.attributes.firstname} ${option.attributes.lastname}`
            }));
        } else if (typeof options !== "undefined" && options.length && options[0].type === "Currency") {
            return options.map(option => ({
                value: option.attributes.code,
                label: `${option.attributes.code}`,
                details: option.attributes
            }));
        } else if (typeof options !== "undefined" && options.length && (options[0].type === "EmailTemplate" || options[0].type === "SmsTemplate")) {
            return options.map(option => ({
                value: option.attributes.id,
                label: `${option.attributes.title}`
            }));
        }  else if (typeof options !== "undefined" && options.length && (options[0].type === "Content" || options[0].type === "Content")) {
            return options.map(option => ({
                value: option.attributes.id,
                label: `${option.attributes.name}`
            }));
        } else if (typeof options !== "undefined" && options.length && options[0].type === "PaymentMethod") {
            return options.map(option => ({
                value: option.id,
                label: `${option.attributes.name}`
            }));
        } else if (typeof options !== "undefined" && options.length && options[0].type === "UserGroup") {
            return options.map(option => ({
                value: option.id,
                label: `${option.attributes.name}`
            }));
        } else if (typeof options !== "undefined" && options.length && options[0].type === "Address") {
            return options.map(option => ({
                value: option.id,
                label: <span>{option.attributes.corporate ? <i className={"icon-line-awesome-building"}/> :
                    <i className={"icon-feather-user"}/>} {option.attributes.company_title}</span>,
                details: option.attributes
            }));
        } else {
            return typeof options === "object" ? options.map(option => ({
                value: option.id,
                label: option.name
            })) : [];
        }
    };

    getOptions = (inputValue, callback) => {
        const {url} = this.props;
        let self = this
        const limit = this.props.limit || 10;
        let queryParams = [];
        if (this.props.searchKey)
            queryParams.push(`${this.props.searchKey}=${inputValue}`);
        queryParams.push(`page[size]=${limit}`);

        if (typeof this.props.filters === "object") {
            Object.keys(this.props.filters).forEach(item => {
                let itemVal = this.props.filters[item];
                if (itemVal !== false) {
                    queryParams.push(item + '=' + itemVal)
                }
            })
        }

        const queryAdder = url.indexOf('?') === -1 ? '?' : '&';
        let fetchURL = `${url}${queryAdder}${queryParams.join("&")}`;
        let requestObj = request
        if (this.props.isClient)
            requestObj = requestObj.setClient()

        requestObj.get(fetchURL).then(r => r.json()).then(response => {
            const results = response.data;
            if (self.props.mapOptionsToValues)
                callback(self.props.mapOptionsToValues(results));
            else callback(self.mapOptionsToValues(results));
        })
    };

    loadDefaultOptions(selectFirst) {
        this.getOptions("", (options) => {
            this.setState({defaultOptions: options}, () => {
                if (typeof selectFirst === "boolean" && selectFirst && options.length)
                    this.handleChange(options[0])
            })
        })
    }

    render() {
        let value = this.state.selectedOption || this.props.defaultValue || []
        // if(typeof this.props.firstSelected === "boolean" && this.props.firstSelected && typeof this.state.defaultOptions === "object" && this.state.defaultOptions.length > 0)
        //     value = this.state.defaultOptions[0]
        return (<>
                <AsyncSelect
                    styles={customSelectStyles}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={typeof this.props.isMulti === "boolean" && this.props.isMulti ? {
                        Option: GlOption,
                        ValueContainer: GlValueContainer
                    } : {}}
                    inputId={this.state.inputId}
                    // value={value}
                    defaultOptions={this.state.defaultOptions}
                    loadOptions={this.getOptions}
                    placeholder={this.state.placeholder}
                    onChange={this.handleChange}
                    onFocus={e => {
                        e.target.autocomplete = "nope";
                        e.target.spellcheck = "nope";
                    }}
                    {...this.props}
                />
            </>
        );
    }
}

export default GlSelect