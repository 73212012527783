import React from "react"
import {Col, Input, Label, Row, UncontrolledTooltip} from "reactstrap";
import InputWithIcon from "../../../Elements/InputWithIcon";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import Select from "react-select";
import classnames from "classnames";
import GlSelect from "../../../Components/GlSelect";
import CurrencyModel from "../../../Models/CurrencyModel";
import {Select38Px} from "../../../Conts";
import {react_intl} from "../../../index";

class MethodSettings extends React.Component {
    state = {
        randomId: Math.random() * 5000
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                randomId: Math.random() * 5000
            })
        }
    }

    render() {
        let {details, id, settingKey, handler, value, z_index_value} = this.props
        let {options} = details
        return <tr className={classnames("position-relative", {"d-none": details.type === "hidden"})}
                   style={{zIndex: z_index_value ? this.props.z_index_value : 0}}>
            <th>
                <span className={"d-inline"}>{details.title}</span>{" "}
                {details.description ? <div className={"d-inline"}>
                    <UncontrolledTooltip
                        target={`settings-${id}-key-${settingKey}`}>{details.description}</UncontrolledTooltip>
                    <i id={`settings-${id}-key-${settingKey}`} className={"icon-feather-info"}/>
                </div> : ""}
            </th>
            <td>
                {details.type === "hidden" && <input name={settingKey} value={value} type={"hidden"}/>}
                {details.type === "text" && <Input name={settingKey} value={value} onChange={handler}/>}
                {details.type === "password" &&
                <Input type={"password"} name={settingKey} value={value} onChange={handler}/>}
                {details.type === "checkbox" &&
                <CustomCheckbox type={"checkbox"} name={settingKey} onChange={handler} checked={value === "on"}/>}
                {details.type === "currency" && <GlSelect
                    url={'/currency'}
                    placeholder={react_intl.formatMessage({id: "admin.payment.method_settings.currency_placeholder"})}
                    styles={Select38Px}
                    closeMenuOnSelect={true}
                    isClearable={true}
                    value={value ? {
                        value: value,
                        label: value
                    } : null}
                    onChange={(e) => {
                        handler({
                            target: {
                                name: settingKey,
                                value: (e !== null) ? e.value : null,
                                type: "select"
                            }
                        })
                    }}
                />}
                {details.type === "select" && this.state.randomId && <Select options={options}
                                                                             styles={Select38Px}
                                                                             value={options ? options.find(i => i.value === value) : false}
                                                                             name={settingKey}
                                                                             onChange={(e) => {
                                                                                 handler({
                                                                                     target: {
                                                                                         name: settingKey,
                                                                                         value: e.value,
                                                                                         type: "select"
                                                                                     }
                                                                                 })
                                                                             }}/>}
            </td>
        </tr>;
    }
}

export default MethodSettings