import React from "react"
import {Button, Col, Input, Label, Row} from "reactstrap";
import MultiRadio from "./MultiRadio";
import classnames from "classnames";
import InputWithIcon from "./InputWithIcon";
import CustomCheckbox from "./CustomCheckbox";
import CityStateSelector from "./CityStateSelector";
import {useForm} from 'react-hook-form';

export const ErrorLabel = (props) => {
    return <span className={"gl-input-error-warning text-danger"}>{props.text}</span>
}

const BillingProfileForm = (props) => {
    let information = props.data
    let invoice_type = information.corporate ? "corporate" : "personal"
    let {onChange} = props

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        defaultValues: information,
        mode: 'onBlur'
    });
    const onSubmit = handleSubmit((data) => {
        console.log(data);
        props.save(data);
    },function (data)
    {
        console.log(data)
    });

    let taxNumberLength = invoice_type === "corporate" ? 10 : 11
    return <form onSubmit={onSubmit}>
        <Row>
            <Col sm={12}>
                <Label className={classnames({"d-none": invoice_type === "corporate"})}>Başlık</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        icon={"icon-line-awesome-pencil"}
                        defaultValue={information.title}
                        {...register("title", {required: true, maxLength: 30})}
                    />
                    {errors.title?.type === 'required' && <ErrorLabel text={"Bu alan zorunludur"}/>}
                    {errors.title?.type === 'maxLength' && <ErrorLabel text={"En fazla 30 karakter giriniz"}/>}
                </div>
            </Col>
        </Row>
        <Row className={"border-bottom bg-light pb-2 pt-2 mb-2"}>
            <Col sm={12} lg={4}>
                <Label>Fatura Tipi:</Label>
            </Col>
            <Col sm={12} lg={8} className={"text-center"}>
                <MultiRadio
                    onChange={(e) => {
                        onChange({
                            target: {
                                name: "corporate",
                                value: e.target.value === "corporate"
                            }
                        })
                    }}
                    options={[
                        {
                            label: "Bireysel Fatura",
                            value: "personal",
                            color: "primary"
                        },
                        {
                            label: "Kurumsal Fatura",
                            value: "corporate",
                            color: "dark"
                        }
                    ]}
                    value={invoice_type}
                />
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Label className={classnames({"d-none": invoice_type === "corporate"})}>Ad Soyad</Label>
                <Label className={classnames({"d-none": invoice_type !== "corporate"})}>Firma Unvanı</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        {...register("company_title", {required: true, maxLength: 50})}
                        defaultValue={information.company_title}
                        name={"company_title"}
                        onChange={onChange}
                    />
                    {errors.company_title?.type === 'required' && <ErrorLabel text={"Geçerli bir isim giriniz."}/>}
                    {errors.company_title?.type === 'maxLength' &&
                    <ErrorLabel text={"En fazla 50 karakter girebilirsiniz."}/>}
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={6}>
                <Label className={classnames({"d-none": invoice_type === "corporate"})}>T.C. Kimlik No</Label>
                <Label className={classnames({"d-none": invoice_type !== "corporate"})}>Vergi No</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        disabled={information.no_citizenship}
                        className={information.no_citizenship ? "disabled" : ""}
                        name={"tax_number"}
                        defaultValue={information.tax_number}
                        pattern="[0-9]*"
                        {...register("tax_number", {
                            required: true,
                            maxLength: taxNumberLength,
                            minLength: taxNumberLength
                        })}
                    />
                    {errors.tax_number &&
                    <ErrorLabel text={`Geçerli bir ${invoice_type === "corporate" ? "vergi no" : "T.C."} giriniz.`}/>}
                </div>
                <div className={classnames("mb-2", {"d-none": invoice_type === "corporate"})}>
                    <Label><CustomCheckbox checked={information.no_citizenship}
                                           onChange={(e) => {
                                               let checkbox = e.target
                                               onChange({
                                                   target: {
                                                       name: "no_citizenship",
                                                       value: checkbox.checked
                                                   }
                                               }, () => {
                                                   if (checkbox.checked) {
                                                       onChange({
                                                           target: {
                                                               name: "tax_number",
                                                               value: "22222222222"
                                                           }
                                                       })
                                                   }
                                               })
                                           }}
                    /> T.C. Vatandaşı Değilim</Label>
                </div>
            </Col>
            <Col sm={6} className={classnames({"d-none": invoice_type !== "corporate"})}>
                <Label>Vergi Dairesi</Label>
                <div className="gl-input-verification-group">
                    <input
                        {...register("tax_office", {required: false, maxLength: 50})}
                        name={"tax_office"} value={information.tax_office} onChange={onChange}/>
                    {errors.tax_office && <ErrorLabel text={"Geçerli bir değer giriniz."}/>}
                </div>
            </Col>
            <Col sm={6}>
                <Label>Telefon Numarası</Label>
                <div className="gl-input-verification-group">
                    <input
                        pattern="[0-9]*"
                        {...register("phone", {
                            required: true,
                            maxLength: 12,
                            minLength: 10
                        })}
                        name={"phone"} value={information.phone} onChange={onChange}/>
                    {errors.phone && <ErrorLabel text={"Geçerli bir telefon numarası giriniz"}/>}
                </div>
            </Col>
            <Col sm={6}>
                <Label>E-posta Adresi</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        {...register("email", {
                            required: "Geçerli bir e-posta giriniz",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Geçerli bir e-posta giriniz"
                            }
                        })}
                        name={"email"} value={information.email} onChange={onChange}/>
                    {errors.email && <ErrorLabel text={errors.email.message}/>}
                </div>
            </Col>
            <Col sm={6}>
                <Label>Açık Adres</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        {...register("address1", {required: true, minLength: 5})}
                        name={"address1"} defaultValue={information.address1} onChange={onChange}/>
                    {errors.address1 && <ErrorLabel text={"Geçerli bir adres giriniz"}/>}
                </div>
            </Col>
            <Col sm={6}>
                <Label>Adres (devamı)</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        {...register("address2", {required: false})}
                        name={"address2"} defaultValue={information.address2} onChange={onChange}/>
                </div>
            </Col>
        </Row>
        <CityStateSelector
            city={information.city}
            state={information.state}
            stateProps={{...register("state", {required: false})}}
            onChange={(result) => {
                onChange({
                    target: {
                        name: "city",
                        value: result.city
                    }
                }, () => {
                    onChange({
                        target: {
                            name: "state",
                            value: result.state
                        }
                    })
                })
            }}/>
        <Row>
            <Col sm={6}>
                <Label>Posta Kodu</Label>
                <div className={"gl-input-verification-group"}>
                    <input
                        onChange={onChange}
                        {...register("postal_code", {required: true, minLength: 3})}
                        name={"postal_code"}
                        defaultValue={information.postal_code}/>
                    {errors.postal_code && <ErrorLabel text={"Posta kodu geçersiz"}/>}
                </div>
            </Col>
        </Row>
        <Row className={"mt-2 pt-2 border-top"}>
            <Col className={"text-right"}>
                <Button /*onClick={() => this.props.save()}*/ type={"submit"} size={"sm"}>Gönder</Button>
            </Col>
        </Row>
    </form>
}

export default BillingProfileForm