import React from "react"
import {Card, CardHeader} from "@material-ui/core";
import PaymentMethodModel from "../Models/PaymentMethodModel";
import {Button, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";
import {Banks, getBankObject} from "../Banks";
import {Link} from "react-router-dom";
import {Skeleton} from "@material-ui/lab";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../index";
import {Scrollbars} from 'react-custom-scrollbars';


const PaymentMethod = (props) => {
    let method_details = getBankObject(props.method)
    if (typeof method_details === "undefined")
        return ""
    return <Col className={"method-image"} sm={12} md={6} lg={4} style={{
        width: "100%",
        height: 50,
        background: `url('${(method_details.image || {}).default}') no-repeat center`,
        backgroundSize: "70px"
    }}></Col>
}

const NoPaymentMethod = () => {
    return <div className={"text-center pl-3 pt-3 pr-3"}>
        <i className={" icon-line-awesome-ban"} style={{fontSize: 32}}/>
        <h5><FormattedMessage id={"components.active_payment_methods.active_methods"}/></h5>
        <p className={"text-muted font-italic"}><FormattedMessage
            id={"components.active_payment_methods.to_get_payment"}/></p>
    </div>
}

const ActivePaymentMethodsSkeleton = (props) => {
    return <>
        <div className={"d-flex"}>
            <Skeleton variant={"rect"} width={120} height={36} className={"mr-2 mb-2 rounded"}/>
            <Skeleton variant={"rect"} width={120} height={36} className={"mr-2 mb-2 rounded"}/>
            <Skeleton variant={"rect"} width={120} height={36} className={"rounded"}/>
        </div>
        <div className={"d-flex"}>
            <Skeleton variant={"rect"} width={90} height={36} className={"mr-2 rounded"}/>
            <Skeleton variant={"rect"} width={90} height={36} className={"mr-2 rounded"}/>
            <Skeleton variant={"rect"} width={90} height={36} className={"mr-2 rounded"}/>
            <Skeleton variant={"rect"} width={90} height={36} className={"rounded"}/>
        </div>
    </>
}

class ActivePaymentMethods extends React.Component {
    state = {
        methods: [],
        loading: true
    }

    loadMethods = () => {
        let activeClasses = []
        PaymentMethodModel.getActiveMethods()
            .then(r => r.json())
            .then(r => {
                let {data} = r
                data.forEach(item => {
                    let {attributes} = item
                    activeClasses.push(attributes.class)
                })
                this.setState({
                    methods: activeClasses,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.loadMethods()
    }

    render() {
        let {methods, loading} = this.state
        let count = ``
        if (!loading)
            count = ` (${methods.length})`
        return <Card>
            <CardHeader
                disableTypography
                className={"border-bottom paymendo-grad text-white rounded-0 m-0"}
                title={react_intl.formatMessage({id: "components.active_payment_methods.active_payment_methods"}, {count: count})}
            >
            </CardHeader>
            <Scrollbars style={{width: "100%", height: 250}}>
                <CardBody className={"text-center pt-0 pb-0 active-payment-methods"} style={{height: 250}}>
                    <Row>
                        {loading ? <Col sm={12} className={"pt-3 pb-3"}>
                            <ActivePaymentMethodsSkeleton/>
                        </Col> : methods.length === 0 ? <NoPaymentMethod/> : methods.map((item, i) => {
                            return <PaymentMethod method={item} key={`Payment-Method-${i}`}/>
                        })}
                    </Row>
                </CardBody>
            </Scrollbars>
            <CardFooter className={"text-center bg-light"}>
                <Link to={'/admin/payment-methods'}
                      className={"btn btn-default btn-sm button-sliding-icon"}>{react_intl.formatMessage({id: "components.active_payment_methods.manage"})}
                    <i
                        className={"icon-feather-arrow-right"}/></Link>
            </CardFooter>
        </Card>;
    }
}

export default ActivePaymentMethods