import {
    email_shortcodes_cron_failed_notification,
    email_shortcodes_cron_success_notification,
    email_shortcodes_failed_admin_login,
    email_shortcodes_failed_user_login,
    email_shortcodes_new_notification,
    email_shortcodes_new_order_admin,
    email_shortcodes_new_order_user, email_shortcodes_new_user_credentials,
    email_shortcodes_order_completed_admin,
    email_shortcodes_order_completed_user,
    email_shortcodes_password_changed,
    email_shortcodes_remind_mail,
    email_shortcodes_transaction_completed_admin,
    email_shortcodes_transaction_completed_user,
    email_shortcodes_transaction_failed_admin,
    email_shortcodes_transaction_refund_admin,
    email_shortcodes_transaction_refund_user,
    email_shortcodes_without_template
} from "./EmailTemplateVariables";
import {
    sms_shortcodes_failed_admin_login,
    sms_shortcodes_failed_user_login,
    sms_shortcodes_new_notification,
    sms_shortcodes_new_order_admin,
    sms_shortcodes_new_order_user,
    sms_shortcodes_order_completed_admin,
    sms_shortcodes_order_completed_user,
    sms_shortcodes_password_changed,
    sms_shortcodes_remind_sms, sms_shortcodes_transaction_completed_admin, sms_shortcodes_transaction_completed_user,
    sms_shortcodes_transaction_failed_admin,
    sms_shortcodes_transaction_refund_admin,
    sms_shortcodes_transaction_refund_user,
    sms_shortcodes_without_template
} from "./SmsTemplateVariables";

export const Select38Px = {
    container: (provided, state) => ({
        ...provided,
        // zIndex: 999
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 999
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        display: "block",
        height: "38px",
        paddingTop: 0,
        paddingBottom: 0,
        // zIndex: 999,
    }),
    Input: (provided, state) => ({
        ...provided,
        marginTop: "5px"
    })
}

export const BillingProfileLabels = {
    "title": "Adres Başlığı",
    "company_title": "Şirket Unvanı",
    "address_type": "Adres Tipi",
    "email": "E-posta",
    "phone": "Telefon",
    "city": "İl",
    "state": "İlçe",
    "tax_number": "Vergi Numarası",
    "tax_office": "Vergi Dairesi",
    "address1": "Adres",
    "address2": "Adres(devamı)",
    "postal_code": "Posta Kodu",
    "country": "Ülke",
}

export const AccountTypes = [
    {
        label: "Tümü",
        value: ""
    },
    {
        label: "Kurumsal",
        value: "corporate"
    },
    {
        label: "Bireysel",
        value: "personal"
    }
]
export const AccountStatuses = [
    {
        label: "Tümü",
        value: "all"
    },
    {
        label: "Aktif",
        value: "1"
    },
    {
        label: "Pasif",
        value: "0"
    }
]

export const email_templates = [
    {
        key: "new_user_credentials",
        title: "Yeni Kayıt - Hesap Giriş Bilgileri (müşteri)",
        onOff: true,
        shortcodes: email_shortcodes_new_user_credentials
    },
    {
        key: "cron_success_notification",
        title: "Zamanlı Görevler Tamamlandı",
        onOff: true,
        shortcodes: email_shortcodes_cron_success_notification
    },
    {
        key: "cron_failed_notification",
        title: "Zamanlı Görevler Tamamlanamadı",
        onOff: true,
        shortcodes: email_shortcodes_cron_failed_notification
    },
    {
        key: "without_template",
        title: "Özel Şablon",
        onOff: false,
        shortcodes: email_shortcodes_without_template
    },
    {
        key: "password_reset",
        title: "Şifre Sıfırlama",
        onOff: false
    },
    {
        key: "new_notification",
        title: "Yeni Bildirim",
        onOff: true,
        shortcodes: email_shortcodes_new_notification
    },
    {
        key: "remind_mail",
        title: "Ödeme Bildirimi",
        onOff: false,
        shortcodes: email_shortcodes_remind_mail
    },
    {
        key: "failed_admin_login",
        title: "Yönetici Hesabına Giriş Teşebbüsü",
        onOff: true,
        shortcodes: email_shortcodes_failed_admin_login
    },
    {
        key: "failed_user_login",
        title: "Müşteri Hesabına Giriş Teşebbüsü",
        onOff: true,
        shortcodes: email_shortcodes_failed_user_login
    },
    {
        key: "password_changed",
        title: "Kullanıcı Şifresi Değiştirildi",
        onOff: true,
        shortcodes: email_shortcodes_password_changed
    },
    {
        key: "new_order_user",
        title: "Yeni Sipariş Bildirimi (müşteri)",
        onOff: true,
        shortcodes: email_shortcodes_new_order_user
    },
    {
        key: "new_order_admin",
        title: "Yeni Sipariş Bildirimi (yönetici)",
        onOff: true,
        shortcodes: email_shortcodes_new_order_admin
    },
    {
        key: "order_completed_user",
        title: "Sipariş Tamamlandı (müşteri)",
        onOff: true,
        shortcodes: email_shortcodes_order_completed_user
    },
    {
        key: "order_completed_admin",
        title: "Sipariş Tamamlandı (yönetici)",
        onOff: true,
        shortcodes: email_shortcodes_order_completed_admin
    },
    {
        key: "transaction_refund_user",
        title: "İade Bildirimi (müşteri)",
        onOff: true,
        shortcodes: email_shortcodes_transaction_refund_user
    },
    {
        key: "transaction_refund_admin",
        title: "İade Bildirimi (yönetici)",
        onOff: true,
        shortcodes: email_shortcodes_transaction_refund_admin
    },
    {
        key: "transaction_failed_user",
        title: "Başarısız Ödeme Bildirimi (müşteri)",
        onOff: true,
        shortcodes: email_shortcodes_failed_user_login
    },
    {
        key: "transaction_failed_admin",
        title: "Başarısız Ödeme Bildirimi (yönetici)",
        onOff: true,
        shortcodes: email_shortcodes_transaction_failed_admin
    },
    {
        key: "transaction_completed_user",
        title: "Ödeme Bildirimi (müşteri)",
        onOff: true,
        shortcodes: email_shortcodes_transaction_completed_user
    },
    {
        key: "transaction_completed_admin",
        title: "Ödeme Bildirimi (yönetici)",
        onOff: true,
        shortcodes: email_shortcodes_transaction_completed_admin
    },
    {
        key: "new_register",
        title: "Yeni Kayıt(müşteri)",
        onOff: true
    }
]
export const configurable_email_templates = email_templates.filter(item => item.onOff)

export const sms_templates = [
    {
        key: "without_template",
        title: "Özel Şablon",
        onOff: false,
        shortcodes: sms_shortcodes_without_template
    },
    {
        key: "new_notification",
        title: "Yeni Bildirim",
        onOff: true,
        shortcodes: sms_shortcodes_new_notification
    },
    {
        key: "remind_sms",
        title: "Ödeme Hatırlatma",
        onOff: false,
        shortcodes: sms_shortcodes_remind_sms
    },
    {
        key: "failed_admin_login",
        title: "Yönetici Hesabına Giriş Teşebbüsü",
        onOff: true,
        shortcodes: sms_shortcodes_failed_admin_login
    },
    {
        key: "failed_user_login",
        title: "Müşteri Hesabına Giriş Teşebbüsü",
        onOff: true,
        shortcodes: sms_shortcodes_failed_user_login
    },
    {
        key: "password_changed",
        title: "Kullanıcı Şifresi Değiştirildi",
        onOff: true,
        shortcodes: sms_shortcodes_password_changed
    },
    {
        key: "new_order_user",
        title: "Yeni Sipariş Bildirimi (müşteri)",
        onOff: true,
        shortcodes: sms_shortcodes_new_order_user
    },
    {
        key: "new_order_admin",
        title: "Yeni Sipariş Bildirimi (yönetici)",
        onOff: true,
        shortcodes: sms_shortcodes_new_order_admin
    },
    {
        key: "order_completed_user",
        title: "Sipariş Tamamlandı (müşteri)",
        onOff: true,
        shortcodes: sms_shortcodes_order_completed_user
    },
    {
        key: "order_completed_admin",
        title: "Sipariş Tamamlandı (yönetici)",
        onOff: true,
        shortcodes: sms_shortcodes_order_completed_admin
    },
    {
        key: "transaction_refund_user",
        title: "İade Bildirimi (müşteri)",
        onOff: true,
        shortcodes: sms_shortcodes_transaction_refund_user
    },
    {
        key: "transaction_refund_admin",
        title: "İade Bildirimi (yönetici)",
        onOff: true,
        shortcodes: sms_shortcodes_transaction_refund_admin
    },
    {
        key: "transaction_failed_user",
        title: "Başarısız Ödeme Bildirimi (müşteri)",
        onOff: true
    },
    {
        key: "transaction_failed_admin",
        title: "Başarısız Ödeme Bildirimi (yönetici)",
        onOff: true,
        shortcodes: sms_shortcodes_transaction_failed_admin
    },
    {
        key: "transaction_completed_user",
        title: "Ödeme Bildirimi (müşteri)",
        onOff: true,
        shortcodes: sms_shortcodes_transaction_completed_user
    },
    {
        key: "transaction_completed_admin",
        title: "Ödeme Bildirimi (yönetici)",
        onOff: true,
        shortcodes: sms_shortcodes_transaction_completed_admin
    }
]
export const configurable_sms_templates = sms_templates.filter(item => item.onOff)

export const get_user_id = () => {
    let admin_profile = localStorage.getItem('admin_profile')
    admin_profile = JSON.parse(admin_profile) || {}
    return admin_profile.id
}