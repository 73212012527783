import {Button} from "reactstrap";
import React from "react";
import {ClientAuthorizationObject} from "../../Lib/Authorization";
import AdminSidebarProfile from "../Admin/Blocks/AdminSidebarProfile";
import {get_main_config, react_intl} from "../../index";
import {
    AiFillHome,
    AiOutlineUser,
    GoVerified, GrAnnounce,
    GrList,
    RiHistoryFill
} from "react-icons/all";
import {frontendBaseUrl} from "../../Config";
import classnames from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";

const menu = () => [
    {
        link: "/cp",
        label: "",
        icon: <AiFillHome/>
    },
    {
        link: "/cp/quick-payment",
        label: react_intl.formatMessage({id: "client.header.quick_pay"}),
        icon: <GoVerified/>
    },
    {
        link: "/cp/orders",
        label: react_intl.formatMessage({id: "client.header.invoices"}),
        icon: <GrList/>
    },
    {
        link: "/cp/transactions",
        label: react_intl.formatMessage({id: "client.header.payments"}),
        icon: <RiHistoryFill/>
    },
    {
        link: "/cp/announcements",
        label: react_intl.formatMessage({id: "client.header.campaign_announcements_txt"}),
        icon: <GrAnnounce/>
    },
    {
        link: "/cp/settings",
        label: react_intl.formatMessage({id: "client.header.my_account"}),
        icon: <AiOutlineUser/>
    }

]

class Header extends React.Component {
    state = {
        loading_logout: false
    }

    render() {
        let {history} = this.props

        let link = window.location.href
        link = link.replace(frontendBaseUrl, '')

        return <header id="header-container" className="fullwidth position-relative">
            <div id="header">
                <div className="container">
                    <div className="d-flex">
                        <div id="logo" className={"cursor-pointer pl-0 pr-3"} onClick={() => {
                            this.props.history.push('/')
                        }}>
                            <img src={get_main_config("system_general", "logo_url")}
                                 alt={"logo"}
                            />
                        </div>

                        <nav id="navigation" className={"w-100 user-panel-top-menu"}>
                            <ul id="responsive" className={"w-100"} style={{lineHeight: "55px"}}>
                                {ClientAuthorizationObject.isLoggedIn() ? menu().map(item => <li
                                    key={`header-menu-item-${item.label}`}
                                    className={classnames("cursor-pointer mr-2 ml-2", {
                                        active: link === item.link
                                    })}
                                    onClick={() => {
                                        history.push(item.link)
                                    }}
                                >{typeof item.icon !== "undefined" && item.icon}<span
                                    className={"ml-1"}>{item.label}</span></li>) : ""}
                                {" "}
                                <li className={"float-right w-auto profile-badge"}>
                                    {ClientAuthorizationObject.isLoggedIn() ?
                                        <AdminSidebarProfile
                                            style={{
                                                lineHeight: "32px",
                                                float: "right",
                                                marginTop: 12
                                            }}
                                            onClick={() => {
                                                history.push('/cp')
                                            }}
                                            history={this.props.history}
                                            type={"client"}
                                        />
                                        :
                                        <Button size={"sm"} color={"primary"} onClick={() => {
                                            history.push("/cp/login")
                                        }}><FormattedMessage id={"client.header.consumer_login"}/></Button>
                                    }
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    }
}

export default injectIntl(Header)