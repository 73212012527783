export const sms_shortcodes_new_user_credentials = [
    {slug: 'password', title: 'Şifre'}
]

export const sms_shortcodes_base = [
    {slug: 'user_id', title: 'ID'},
    {slug: 'username', title: 'Kullanıcı Adı'},
    {slug: 'email', title: 'E-posta'},
    {slug: 'enabled', title: 'Durumu'},
    {slug: 'last_login', title: 'Son Giriş Tarihi'},
    {slug: 'firstname', title: 'Adı'},
    {slug: 'lastname', title: 'Soyadı'},
    {slug: 'mobile_phone', title: 'Cep Telefonu'},
    {slug: 'balance', title: 'Balance'},
    {slug: 'language', title: 'Dil'},
    {slug: 'user_group', title: 'Müşteri Grubu'}
]
export const sms_shortcodes_system_config = [
    {slug: 'site_name', title: 'Site Başlığı'},
    {slug: 'system_url', title: 'Sistem URL'},
    {slug: 'system_api_base_url', title: 'Sistem API URL'},
    {slug: 'logo_url', title: 'Logo URL'},
    {slug: 'deny_registers', title: 'Yeni Müşteri Kabulü'}
]
export const sms_shortcodes_company_info = [
    {slug: 'company_name', title: 'Firma Unvanı'},
    {slug: 'address', title: 'Adres'},
    {slug: 'tax_office', title: 'Vergi Dairesi'},
    {slug: 'tax_number', title: 'Vergi Numarası'},
    {slug: 'email', title: 'E-posta Adresi'},
    {slug: 'phone', title: 'Telefon Numarası'}
]

export const sms_shortcodes_without_template = [
    {slug: 'subject', title: 'Başlık'},
    {slug: 'body', title: 'Mesaj İçeriği'}
]
export const sms_shortcodes_phone_verification = [
    {slug: 'code', title: 'Doğrulama Kodu'},
    {slug: 'expires_at', title: 'Kod Geçerlilik(tarih)'},
    {slug: 'expiry', title: 'Kod Geçerlilik(saniye)'}
]
export const sms_shortcodes_password_reset = [
    {slug: 'hash', title: 'Sıfırlama Anahtarı'}
]
export const sms_shortcodes_new_notification = [
    {slug: 'notification_id', title: 'Bildirim ID'},
    {slug: 'notification_user_id', title: 'Bildirim Kullanıcı ID'},
    {slug: 'notification_type', title: 'Bildirim Tipi'},
    {slug: 'notification_title', title: 'Bildirim Başlığı'},
    {slug: 'notification_details', title: 'Bildirim İçeriği'},
    {slug: 'notification_created', title: 'Bildirim Olş. Tarihi'},
    {slug: 'notification_is_read', title: 'Bildirim Okunma Durumu'},
    {slug: 'notification_deleted', title: 'Bildirim Silinme Durumu'}
]
export const sms_shortcodes_order = [
    {slug: 'order_id', title: 'Fatura ID'},
    {slug: 'order_num', title: 'Fatura Numarası'},
    {slug: 'order_due_date', title: 'Fatura Son Ödeme Tarihi'},
    {slug: 'order_amount', title: 'Fatura Tutarı'},
    {slug: 'order_balance', title: 'Fatura Kalan Tutar'},
    {slug: 'order_currency_code', title: 'Para Birimi Kodu'},
    {slug: 'order_currency_rate', title: 'Para Birimi Kuru'},
    {slug: 'order_invoice_details', title: 'Fatura Detayları'},
    {slug: 'order_status', title: 'Fatura Durumu'},
    {slug: 'order_payment_method', title: 'Fatura Ödeme Yöntemi'},
    {slug: 'order_owner', title: 'Fatura Sahibi'},
    {slug: 'order_notes', title: 'Fatura Not'},
    {slug: 'order_token', title: 'Fatura Anahtarı'},
    {slug: 'order_payment_date', title: 'Fatura Ödenme Tarihi'},
    {slug: 'order_created_date', title: 'Fatura Oluşturma Tarihi'},
    {slug: 'order_updated_date', title: 'Sipariş Güncellenme Tarihi'}
]
export const sms_order_shortcodes_for_admin = [
    {slug: 'order_user_id', title: 'Müşteri ID'},
    {slug: 'order_username', title: 'Müşteri Kul. Adı'},
    {slug: 'order_email', title: 'Müşteri E-posat Adresi'},
    {slug: 'order_enabled', title: 'Müşteri Hesap Durumu'},
    {slug: 'order_last_login', title: 'Müşteri Son Giriş Tarihi'},
    {slug: 'order_firstname', title: 'Müşteri Adı'},
    {slug: 'order_lastname', title: 'Müşteri Soyadı'},
    {slug: 'order_mobile_phone', title: 'Müşteri Cep Tel.'},
    {slug: 'order_balance', title: 'Müşteri Hesap Bakiyesi'},
    {slug: 'order_language', title: 'Müşteri Dili'},
    {slug: 'order_user_group', title: 'Müşteri Grubu'}

]
export const sms_shortcodes_transaction = [
    {slug: 'transaction_id', title: 'İşlem ID'},
    {slug: 'transaction_order_id', title: 'İşlem Fatura ID'},
    {slug: 'transaction_payment_method', title: 'İşlem Ödeme Yöntemi'},
    {slug: 'transaction_related_transaction', title: 'Bağlantılı İşlem'},
    {slug: 'transaction_transaction_id', title: 'İşlem No'},
    {slug: 'transaction_oid', title: 'İşlem OrderId'},
    {slug: 'transaction_total_amount', title: 'İşlem Tutarı'},
    {slug: 'transaction_order_amount', title: 'İşlem Fatura Tutarı'},
    {slug: 'transaction_currency_code', title: 'İşlem Para Birimi'},
    {slug: 'transaction_currency_rate', title: 'İşlem Kuru'},
    {slug: 'transaction_installment', title: 'İşlem Taksit Adedi'},
    {slug: 'transaction_fee', title: 'İşlem Ödeme Komisyonu'},
    {slug: 'transaction_date', title: 'İşlem Tarihi'},
    {slug: 'transaction_notes', title: 'İşlem Notu'},
    {slug: 'transaction_status', title: 'İşlem Durumu'}
]
export const sms_shortcodes_transaction_failed = [
    {slug: 'error_message', title: 'Hata Mesajı'},
    {slug: 'bank_class', title: 'Ödeme Kütüphanesi'},
    {slug: 'bank_name', title: 'Ödeme Yöntemi Adı'},
    {slug: 'bank_description', title: 'Ödeme Yöntemi Açıklama'}
]
export const sms_shortcodes_transaction_related = [
    {slug: 'related_transaction_id', title: 'İşlem ID'},
    {slug: 'related_transaction_order_id', title: 'İşlem Fatura ID'},
    {slug: 'related_transaction_payment_method', title: 'İşlem Ödeme Yöntemi'},
    {slug: 'related_transaction_related_transaction', title: 'Bağlantılı İşlem'},
    {slug: 'related_transaction_transaction_id', title: 'İşlem No'},
    {slug: 'related_transaction_oid', title: 'İşlem OrderId'},
    {slug: 'related_transaction_total_amount', title: 'İşlem Tutarı'},
    {slug: 'related_transaction_order_amount', title: 'İşlem Fatura Tutarı'},
    {slug: 'related_transaction_currency_code', title: 'İşlem Para Birimi'},
    {slug: 'related_transaction_currency_rate', title: 'İşlem Kuru'},
    {slug: 'related_transaction_installment', title: 'İşlem Taksit Adedi'},
    {slug: 'related_transaction_fee', title: 'İşlem Ödeme Komisyonu'},
    {slug: 'related_transaction_date', title: 'İşlem Tarihi'},
    {slug: 'related_transaction_notes', title: 'İşlem Notu'},
    {slug: 'related_transaction_status', title: 'İşlem Durumu'}
]
export const sms_shortcodes_remind_sms = [...sms_shortcodes_order] + [
    {slug: 'day_left', title: 'Kalan Gün Sayısı'}
]
export const sms_shortcodes_failed_admin_login = [
    {slug: 'requestData', title: 'İstek Gövdesi'},
    {slug: 'ip', title: 'IP Adresi'}
]
export const sms_shortcodes_failed_user_login = [
    {slug: 'requestData', title: 'İstek Gövdesi'},
    {slug: 'ip', title: 'IP Adresi'}
]
export const sms_shortcodes_password_changed = [
    {slug: 'ip', title: 'IP Adresi'}
]

export const sms_shortcodes_new_order_user = [...sms_shortcodes_order]
export const sms_shortcodes_new_order_admin = [...sms_shortcodes_order].concat([...sms_order_shortcodes_for_admin])
export const sms_shortcodes_order_completed_user = [...sms_shortcodes_order]
export const sms_shortcodes_order_completed_admin = [...sms_shortcodes_order].concat([...sms_order_shortcodes_for_admin])
export const sms_shortcodes_transaction_refund_user = [...sms_shortcodes_order].concat([...sms_shortcodes_transaction]).concat([...sms_shortcodes_transaction_related])
export const sms_shortcodes_transaction_refund_admin = [...sms_shortcodes_order].concat([...sms_order_shortcodes_for_admin]).concat([...sms_shortcodes_transaction]).concat([...sms_shortcodes_transaction_related])
export const sms_shortcodes_transaction_failed_user = [...sms_shortcodes_order].concat([...sms_shortcodes_transaction_failed]);
export const sms_shortcodes_transaction_failed_admin = [...sms_shortcodes_order].concat([...sms_order_shortcodes_for_admin]).concat([...sms_shortcodes_transaction_failed]);
export const sms_shortcodes_transaction_completed_user = [...sms_shortcodes_order].concat([...sms_shortcodes_transaction])
export const sms_shortcodes_transaction_completed_admin = [...sms_shortcodes_order].concat(sms_order_shortcodes_for_admin).concat(sms_shortcodes_transaction)