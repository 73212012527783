import {Button, UncontrolledTooltip} from "reactstrap";
import React from "react"
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

class ButtonRoundedWithIcon extends React.Component{
    constructor(props) {
        super(props);
        this.id = generateUniqueID()
    }
    render() {
        let {size, tooltip} = this.props
        if(!size)
            size = 16
        return <>
            {tooltip ? <UncontrolledTooltip target={`button-${this.id}`}>{tooltip}</UncontrolledTooltip> : ""}
            <Button id={`button-${this.id}`} size={"sm"} className={"rounded-circle"} {...this.props} style={Object.assign({width:size,height:size, lineHeight:`${size}px`, padding: 0},this.props.style)}>
                <i style={{fontSize:size*.9}} className={this.props.icon} />
        </Button>
        </>;
    }
}
export default ButtonRoundedWithIcon