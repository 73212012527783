import {ApiErrorThrow, defaultSnackbarOptions} from "../Config";
import {withSnackbar} from "react-simple-snackbar";
import React from "react"
import {components} from 'react-select';
import GlSelect from "./GlSelect";
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import InvoiceDetailsManager from "./InvoiceDetailsManager";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../index";

// import {react_intl} from "../index";


function getLength(options) {
    return options.reduce((acc, curr) => {
        if (curr.options) return acc + getLength(curr.options);
        return acc + 1;
    }, 0);
}

const Option = (props: OptionProps) => {
    const {
        children,
        innerProps,
    } = props;
    let buttonSize = 25
    // let buttonStyle = {width: buttonSize, height: buttonSize, lineHeight: `${buttonSize}px`}
    return (
        <div {...innerProps}>
            <div className={"d-flex address-selector-option p-3"}>
                <div className={"w-100 text-left"}>{children}</div>
            </div>
        </div>
    );
};


const Menu = props => {
    let addButton = ""
    // if (typeof this.props.modalToggle === "function")
    let parentProps = props.selectProps
    addButton = <div className={"text-center bg-light p-3"}>
        <Button size={"sm"} color={"info"}
                onClick={() => {
                    if (typeof parentProps.modalToggle === "function")
                        parentProps.modalToggle()
                }}
        ><FormattedMessage id={"components.Invoice_Details_Selector_edit_invoice"}/></Button>
    </div>
    return <>
        <components.Menu {...props}>
            {props.children}
            {addButton}
        </components.Menu>
    </>
};

const SingleValue = ({children, ...props}) => {
    let {data} = props

    if (typeof data === "undefined")
        data = {details: {}}

    if (typeof data.details === "object")
        data = data.details;

    return <components.SingleValue {...props}>
        <span className={"w-100 d-block text-excepted pt-1"} style={{fontSize: 16}}>{data.company_title}</span>
        <span
            className={"w-100 d-block text-secondary text-excepted"}>{data.tax_number}{data.tax_office ? ` - ${data.tax_office}` : ""}</span>
        <span className={"w-100 d-block text-secondary text-excepted"}>{data.address1} {data.state} {data.city}</span>

    </components.SingleValue>
};
const selectorHeight = 75

class InvoiceDetailsSelector extends React.Component {
    invoiceSelector = null
    state = {
        modal1: false
    }
    modal1_toggle = () => {
        this.setState({modal1: !this.state.modal1})
        if (this.state.modal1) {
            this.invoiceSelector.current.loadDefaultOptions()
        }
    }

    constructor(props) {
        super(props);
        this.invoiceSelector = React.createRef()
    }

    render() {
        let {user_id, canChange} = this.props
        if (typeof user_id !== "number")
            return ""

        return <>
            <GlSelect
                closeMenuOnSelect={true}
                isDisabled={typeof canChange === "boolean" && !canChange}
                url={`/api/v2/user/${user_id}/address`}
                ref={this.invoiceSelector}
                placeholder={react_intl.formatMessage({id: "components.Invoice_Details_Selector_billing_info"})}
                components={{
                    SingleValue: SingleValue,
                    Option: Option,
                    Menu: Menu
                }}
                modalToggle={this.modal1_toggle}
                onChange={this.props.onChange}
                isClearable={true}
                noOptionsMessage={() => <div className={"text-center"}>
                    <p className={"text-muted"}><FormattedMessage
                        id={"components.Invoice_Details_Selector_no_invoice_info"}/></p>
                </div>}
                styles={{
                    singleValue: base => ({
                        ...base,
                        height: selectorHeight
                    }),
                    valueContainer: base => ({
                        ...base,
                        height: selectorHeight
                    }),
                    control: base => ({
                        ...base,
                        height: selectorHeight
                    }),
                    container: base => ({
                        ...base,
                        height: selectorHeight,
                        zIndex: 999
                    })
                }}
                {...this.props}
            />
            <Modal
                isOpen={this.state.modal1}
                toggle={this.modal1_toggle}
                centered={true}
                size={"lg"}
            >
                <ModalHeader toggle={this.modal1_toggle}><FormattedMessage
                    id={"components.Invoice_Details_Selector_billing_information"}/></ModalHeader>
                <ModalBody>
                    <InvoiceDetailsManager
                        useButton={true}
                        toggleModal={this.modal1_toggle}
                        {...this.props} />
                </ModalBody>
            </Modal>
        </>
    }
}

export default withSnackbar(InvoiceDetailsSelector, defaultSnackbarOptions)