import {Badge} from "reactstrap";
import React from "react"
import {react_intl} from "../index";

export const OrderStatuses = () => [
    {
        label: react_intl.formatMessage({id:"invoice_status_labels.unknown"}),
        color: "info",
        icon: "icon-line-awesome-question-circle",
        slug: "unknown"
    },
    {
        label: react_intl.formatMessage({id:"invoice_status_labels.fully_paid"}),
        color: "success",
        icon: "icon-feather-check",
        slug: "fully_paid"
    },
    {
        label: react_intl.formatMessage({id:"invoice_status_labels.unpaid"}),
        color: "warning",
        icon: "icon-feather-clock",
        slug: "unpaid"
    },
    {
        label: react_intl.formatMessage({id:"invoice_status_labels.canceled"}),
        color: "danger",
        icon: "icon-feather-delete",
        slug: "canceled"
    },
    {
        label: react_intl.formatMessage({id:"invoice_status_labels.refunded"}),
        color: "dark",
        icon: "icon-line-awesome-refresh",
        slug: "refunded"
    },
    {
        label: react_intl.formatMessage({id:"invoice_status_labels.draft"}),
        color: "info",
        icon: "icon-line-awesome-clock-o",
        slug: "draft"
    }
]

class OrderStatus extends React.Component {
    statuses = OrderStatuses()

    findElement = (status, attr) => {
        let element = this.statuses.find(item => item.slug === status);
        if (!element)
            return this.findElement("unknown", attr);
        return element[attr]
    }

    getColor = (status) => {
        return this.findElement(status, "color")
    }

    getText = (status) => {
        return this.findElement(status, "label")
    }

    getIcon = (status) => {
        return this.findElement(status, "icon")
    }

    render() {
        let statusColor = this.getColor(this.props.status)
        let statusText = this.getText(this.props.status)
        let iconText = this.getIcon(this.props.status)
        return <div className={"order-status-view"}>
            <Badge color={statusColor}><div className={"text"}>{statusText}</div>{/*<i className={iconText} />*/}</Badge>
        </div>;
    }
}

export default OrderStatus