import {
    Badge,
    Button,
    ButtonGroup, DropdownItem,
    DropdownMenu,
    DropdownToggle, Modal, ModalBody, ModalHeader, Spinner,
    Table,
    UncontrolledButtonDropdown,
    UncontrolledTooltip
} from "reactstrap";
import OrderStatus from "../../../../Elements/OrderStatus";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import ShowPrice from "../../../../Elements/ShowPrice";
import React from "react";
import {ApiErrorThrow, frontendBaseUrl} from "../../../../Config";
import StatusChangeModel from "../../../../Components/StatusChangeModel";
import ConfirmationModalAlert from "../../../../Components/ModalAlerts/ConfirmationModalAlert";
import SendSmsEmailModel from "../../../../Models/SendSmsEmailModel";
import ClientProfileBillingProfile, {defaultAddressFields} from "../../Clients/Widgets/ClientProfileBillingProfile";
import BillingProfileForm from "../../../../Elements/BillingProfileForm";
import OrderModel from "../../../../Models/OrderModel";
import SendNotificationGroup from "../../../../Components/SendNotificationGroup";
import InvoiceDetailsSelector from "../../../../Components/InvoiceDetailsSelector";
import AddressModel from "../../../../Models/AddressModel";
import {getCountryName} from "../../../../Elements/CityStateSelector";
import moment from "moment";
import OrderPdfButton from "../../../../Components/OrderPdfButton";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../../index";

const corporateAddressIcon = "icon-line-awesome-building-o"
const personalAddressIcon = "icon-material-outline-account-circle"

class RightTable extends React.Component {
    shortUrl = frontendBaseUrl + "/pay/order_id/token"
    state = {
        status_modal: false,
        new_status: null,
        invoice_details_modal: false,
        invoice_details: {},
        invoice_details_selected: false
    }

    invoice_details_modal_toggle = () => {
        this.setState({invoice_details_modal: !this.state.invoice_details_modal})
    }

    editToggle = () => {
        this.setState({editToggle: !this.state.editToggle})
    }

    status_modal_toggle = () => {
        this.setState({
            status_modal: !this.state.status_modal
        })
    }

    save = () => {
        let {orderDetails, openSnackbar} = this.props
        let invoice_details = orderDetails.invoice_details
        let invoiceDetailsNotFilled = Object.keys(invoice_details).filter(item => invoice_details[item] !== "").length === 0
        let data = Object.assign(defaultAddressFields, this.state.invoice_details)

        AddressModel.create(orderDetails.user_id.id, data)
            .then(r => r.json())
            .then(r => {
                let createdId = r.data.id

            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    constructor(props) {
        super(props);
        this.status_modal_toggle = this.status_modal_toggle.bind(this)
        this.editToggle = this.editToggle.bind(this)
    }

    render() {
        let {openSnackbar, orderDetails, orderToken, orderId} = this.props
        let {invoice_details_selected} = this.state

        let shortUrlGenerated = "..."
        if (!(typeof orderDetails.id === "undefined" || typeof orderToken === "undefined")) {
            shortUrlGenerated = this.shortUrl.replace("order_id", orderDetails.id).replace("token", orderToken);
        }

        let show_address_details = orderDetails.invoice_details
        if (typeof invoice_details_selected === "object" && invoice_details_selected !== null && typeof invoice_details_selected.company_title === "string")
            show_address_details = invoice_details_selected
        else if (typeof invoice_details_selected === "object" && invoice_details_selected === null)
            show_address_details = {}

        let invoiceDetailsNotFilled = Object.keys(show_address_details).filter(item => show_address_details[item] !== "").length === 0
        if (this.state.invoice_details === null && !invoiceDetailsNotFilled) {
            this.setState({show_address_details})
        }

        let id_loaded = false
        if (typeof orderDetails.user_id === "object" && typeof orderDetails.user_id.id === "number" && parseInt(orderDetails.user_id.id) > 0)
            id_loaded = true

        let row = orderDetails


        return <div>
            <div className="mb-3">
                <Table className={"table-striped border rounded order-detail-table-right"} cellPadding={3}
                       cellSpacing={5}>
                    <tbody>
                    <tr>
                        <th><FormattedMessage id={"admin.orders.details.right_table.status"}/></th>
                        <td className="text-right">
                            <OrderStatus status={orderDetails.status}/>
                        </td>
                    </tr>
                    <tr>
                        <th><i className={"icon-feather-link"}/> <FormattedMessage
                            id={"admin.orders.details.right_table.short_link"}/></th>
                        <td className={"text-right"}>
                            <input id={"order-short-link"}
                                   className={"bg-light pr-2 pl-2 mr-2 mt-0"}
                                   value={shortUrlGenerated}/>
                            <UncontrolledTooltip
                                target={"button-copy"}><FormattedMessage id={"admin.orders.details.right_table.copy"}/></UncontrolledTooltip>
                            <CopyToClipboard text={shortUrlGenerated} onCopy={() => {
                                openSnackbar(<div>
                                    <i className={"icon-line-awesome-check-circle-o"}/> <FormattedMessage
                                    id={"admin.orders.details.right_table.copied"}/>
                                </div>)
                            }}>
                                <Button size={"sm"} id={"button-copy"} color={"primary"}><i
                                    className={"icon-feather-clipboard"}/></Button>
                            </CopyToClipboard>
                        </td>
                    </tr>
                    <tr>
                        <th><i className={"icon-line-awesome-hand-pointer-o"}/> <FormattedMessage
                            id={"admin.orders.details.right_table.quick_actions"}/></th>
                        <td className={"text-right"}>
                            <div className={"d-flex float-right"}>
                                <OrderPdfButton id={orderId}/>
                                <StatusChangeModel orderId={orderId} reload={() => this.props.reload()}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th><i className={"icon-line-awesome-money"}/> <FormattedMessage
                            id={"admin.orders.details.right_table.balance"}/></th>
                        <td className={"text-right"}><ShowPrice amount={orderDetails.balance}
                                                                currencyprefix={orderDetails.currency.prefix}
                                                                currencysuffix={orderDetails.currency.suffix}/>
                        </td>
                    </tr>
                    <tr>
                        <th><FormattedMessage id={"admin.orders.details.right_table.invoice_information"}/></th>
                        <td className={"text-right"}>
                            {this.state.invoice_details_selected || !invoiceDetailsNotFilled ?
                                <>
                                    <div>
                                        <strong>
                                            <i className={show_address_details.corporate ? corporateAddressIcon : personalAddressIcon}/>{" "}
                                            {show_address_details.company_title}
                                        </strong>
                                    </div>
                                    <div className={"text-muted font-italic"}>
                                        <div><i
                                            className={"icon-line-awesome-map-marker"}/> {show_address_details.address1 + (show_address_details.address2 ? " " + show_address_details.address2 : "")}
                                        </div>
                                        <div>{`${show_address_details.state || "-"} / ${show_address_details.city || "-"} / ${getCountryName((show_address_details.country).toUpperCase())}`}</div>
                                        <div>
                                            <FormattedMessage id={"admin.orders.details.right_table.tax_number"}
                                                              values={{tax: show_address_details.tax_number || "-"}}/>{" "}
                                            {show_address_details.corporate && <><FormattedMessage
                                                id={"admin.orders.details.right_table.tax_number"}
                                                values={{tax: show_address_details.tax_office || "-"}}/>
                                            </>}
                                        </div>
                                        <div><i className={"icon-line-awesome-envelope"}/> {show_address_details.email}
                                        </div>
                                        <div><i className={"icon-line-awesome-phone"}/> {show_address_details.phone}
                                        </div>
                                    </div>
                                </> : <>
                                    <div className={"text-danger"}><i className={" icon-material-outline-info"}/>
                                        <FormattedMessage id={"admin.orders.details.right_table.no_info"}/>
                                    </div>
                                </>}
                            {id_loaded && <InvoiceDetailsSelector
                                user_id={orderDetails.user_id.id}
                                defaultValue={!invoiceDetailsNotFilled ? orderDetails.invoice_details : null}
                                orderId={orderDetails.id}
                                canChange={true}
                                onChange={(val) => {
                                    this.invoiceDetailChange(val)
                                }}
                            />}

                        </td>
                    </tr>
                    <tr className={"bg-dark text-white"}>
                        <td className={"text-right d-flex d-md-table-cell order-send-buttons"} colSpan={4}>
                            <SendNotificationGroup
                                type={"sms"}
                                color={"info"}
                                groups={["new_order_user", "remind_sms", "order_completed_user"]}
                                orderId={orderDetails.id}
                                clientId={orderDetails.user_id.id}
                                transactionId={""}
                                openSnackbar={this.props.openSnackbar}
                            />{" "}
                            <SendNotificationGroup
                                type={"email"}
                                color={"info"}
                                groups={["new_order_user", "order_completed_user", "remind_mail"]}
                                orderId={orderDetails.id}
                                clientId={orderDetails.user_id.id}
                                transactionId={""}
                                openSnackbar={this.props.openSnackbar}
                            />
                        </td>

                    </tr>
                    </tbody>
                </Table>
            </div>
        </div>;
    }

    invoiceDetailChange(val, cb) {
        let {openSnackbar} = this.props
        if (typeof val !== "object" || val === null)
            val = {}

        let clear = typeof val.value === "undefined"
        OrderModel.modify(this.props.orderId, {
            address_id: !clear ? val.value : ""
        })
            .then(r => r.json())
            .then(r => {
                this.setState({
                    invoice_details_selected: typeof val.details === "object" ? val.details : null
                }, () => {
                    if (typeof cb === "function")
                        cb()
                })
                openSnackbar(react_intl.formatMessage({id: "admin.orders.details.right_table.information_updated"}))
            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }
}

export default RightTable