import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import BillingProfileForm from "../../../../Elements/BillingProfileForm";
import ClientProfileBillingProfile, {defaultAddressFields, EmptyBilling} from "./ClientProfileBillingProfile";
import {BillingProfileLabels} from "../../../../Conts";
import React from "react";
import AddressModel from "../../../../Models/AddressModel";
import ConfirmationModalAlert from "../../../../Components/ModalAlerts/ConfirmationModalAlert";
import {ApiErrorThrow} from "../../../../Config";
import {react_intl} from "../../../../index";
import {FormattedMessage} from "react-intl";

class BillingProfileWidget extends React.Component {
    state = {
        editModal: false,
        modalOpened: false,
        addresses_modify: {},
        address_id: null,
        deleteModalOpened: false
    }

    editToggle = (details) => {
        this.setState({
            editModal: !this.state.editModal,
            addresses_modify: details,
            address_id: details.id
        })
    }

    deleteToggle = (address_id) => {
        this.setState({
            deleteModalOpened: !this.state.deleteModalOpened,
            address_id: address_id
        })
    }

    toggle = (details) => {
        this.setState({
            modalOpened: !this.state.modalOpened,
            addresses_modify: details,
            address_id: details.id
        })
    }

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.editToggle = this.editToggle.bind(this)
        this.deleteToggle = this.deleteToggle.bind(this)
    }

    onChange = (e, callback) => {
        let {addresses_modify} = this.state
        let _addresses_modify = {...addresses_modify}
        _addresses_modify[e.target.name] = e.target.value

        this.setState({
            addresses_modify: _addresses_modify
        }, () => {
            if (typeof callback === "function")
                callback()
        })
    }

    render() {
        let {loaded, addresses} = this.props
        let {addresses_modify, address_id} = this.state
        return <>

            <ConfirmationModalAlert
                isOpen={this.state.deleteModalOpened}
                toggle={this.deleteToggle}
                title={react_intl.formatMessage({id: "admin.clients.billing_profile.delete_title"})}
                description={react_intl.formatMessage({id: "admin.clients.billing_profile.delete_description"})}
                buttonAction={() => {
                    let {openSnackbar} = this.props
                    AddressModel.delete(this.props.client_id, address_id)
                        .then(r => {
                            if (typeof this.props.reload === "function")
                                this.props.reload()
                            this.deleteToggle(null)
                            openSnackbar(react_intl.formatMessage({id:"admin.clients.billing_profile.saved"}))
                        })
                        .catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            />

            <h4 className={"text-center"}>
                <i className="icon-feather-file-text"/> <FormattedMessage id={"admin.clients.billing_profile.billing_profiles"} />{" "}
                <Button
                    size={"sm"}
                    color={"primary"}
                    className={"ripple-effect text-center p-0 pl-1 pr-1"}
                    style={{height: 24}}
                    onClick={(e) => this.editToggle(defaultAddressFields)}
                >
                    <i className={" icon-feather-plus"}/> <FormattedMessage id={"admin.clients.billing_profile.billing_profiles.add"} />
                </Button>
            </h4>

            <Modal isOpen={this.state.editModal} toggle={this.editToggle} centered={true}>
                <ModalHeader
                    toggle={this.editToggle}>{this.state.address_id ? react_intl.formatMessage({id:"admin.clients.billing_profile.billing_profiles.edit_profiles"}) : react_intl.formatMessage({id:"admin.clients.billing_profile.billing_profiles.add_profile"})}</ModalHeader>
                <ModalBody>
                    {<BillingProfileForm
                        key={"BillingProfileForm"}
                        data={addresses_modify || defaultAddressFields}
                        address_id={address_id}
                        onChange={(e, callback) => {
                            this.onChange(e, callback)
                        }}
                        save={() => {
                            let {openSnackbar} = this.props
                            if (this.state.address_id) {
                                AddressModel.modify(this.props.client_id, this.state.address_id, this.state.addresses_modify)
                                    .then(r => r.json())
                                    .then(r => {
                                        openSnackbar(react_intl.formatMessage({id:"admin.clients.billing_profile.saved"}))
                                        if (typeof this.props.reload === "function")
                                            this.props.reload()
                                        this.editToggle(defaultAddressFields)
                                    })
                                    .catch(r => ApiErrorThrow(r, openSnackbar))
                            } else {
                                AddressModel.create(this.props.client_id, this.state.addresses_modify)
                                    .then(r => r.json())
                                    .then(r => {
                                        openSnackbar(react_intl.formatMessage({id:"admin.clients.billing_profile.saved"}))
                                        if (typeof this.props.reload === "function")
                                            this.props.reload()
                                        this.editToggle(defaultAddressFields)
                                    })
                                    .catch(r => ApiErrorThrow(r, openSnackbar))
                            }
                        }}
                    />}
                </ModalBody>
            </Modal>

            <Modal isOpen={this.state.modalOpened} toggle={this.toggle} centered={true}>
                <ModalHeader toggle={this.toggle}>
                    <FormattedMessage id={"admin.clients.billing_profile.billing_profiles.modal_title"} />
                </ModalHeader>
                <ModalBody>
                    <Table color={"primary"}>
                        {typeof addresses_modify === "object" ? Object.keys(addresses_modify).map((key) => {
                            let item = addresses_modify[key]
                            if (key === "tax_number" && item === "22222222222")
                                item += ` (${react_intl.formatMessage({id:"admin.clients.billing_profile.billing_profiles.non_tc_citizen"})})`
                            if (key === "id" || key === "address_type" || typeof item !== "string" && typeof item !== "number")
                                return ""
                            if (typeof item === "string" && item === "")
                                item = "-"
                            return <tr>
                                <th>{BillingProfileLabels[key]}:</th>
                                <td>{item}</td>
                            </tr>
                        }) : " "}
                    </Table>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
            <div style={{
                overflowX: "hidden",
                overflowY: "scroll",
                maxHeight: 228
            }}>
                {loaded ? addresses.map((item, num) => {
                    let item_modify = {...item}
                    return <>
                        <ClientProfileBillingProfile
                            addresses={item}
                            address_id={item.id}
                            edit={this.editToggle}
                            toggle={this.toggle}
                            addresses_modify={item_modify}
                            deleteToggle={this.deleteToggle}
                        />
                    </>
                }) : <EmptyBilling create={() => this.editToggle(defaultAddressFields)}/>}
            </div>
        </>;
    }
}

export default BillingProfileWidget